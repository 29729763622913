import React from "react"

import { useSwipeable } from "react-swipeable"
const Swipe = () => {
  const handlers = useSwipeable({
    onSwipedLeft: () => console.log("SWIPE LEFT"),
    onSwipedRight: () => console.log("SWIPE RIGHT"),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  })
  return (
    <div {...handlers}>
      <div
        style={{ width: "1000px", height: "1000px", backgroundColor: "red" }}
      ></div>
    </div>
  )
}
export default Swipe
